<template>
  <div class="car-list">
    <confirmation-dialog
      @close="dialogDelete = false"
      :dialog="dialogDelete"
      @confirm="deleteCarParts()"
    />
    <div class="page-header">
      <h1>{{ $route.name }}</h1>
      <div class="add-btn"></div>
    </div>

    <div class="filter old">
      <label>
        {{ $t("commonLayout.select_criteria_before_your_search") }}
        <b-form-select
          id="filterinput"
          type="text"
          class="form-control"
          :options="options"
          @input="reload"
          v-model="filter"
          placeholder="Filter Type"
        ></b-form-select
      ></label>
    </div>
    <div class="custom-table">
      <table
        id="example"
        class="display responsive nowrap"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th class="desktop tablet-l tablet-p mobile-p mobile-l">Id</th>
            <th class="desktop tablet-l tablet-p">Part Code</th>
            <th class="desktop tablet-l tablet-p mobile-p mobile-l">
              Part Name
            </th>
            <th class="none">VIN</th>
            <th class="desktop">Year</th>
            <th class="desktop">Model</th>
            <th class="desktop">Grade</th>
            <th class="desktop">Amount</th>
            <th class="none">Description</th>
            <th>Interchange Description</th>
            <th>Sale Price</th>
            <th>Miles</th>
            <th class="none">Stock Number</th>
            <th>Location</th>
            <th>Interchange</th>
            <th class="desktop">Actions</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import { OLD_INVENTORY } from "@/constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";
import { showSuccess, showFailure } from "../eventbus/action.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import tokenService from "./../services/token.service.js";
export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      table: null,
      items: [],
      options: [
        { text: "VIN", value: "vin" },
        { text: "Model", value: "model" },
        { text: "Part Name", value: "part_name" },
        { text: "Part Code", value: "part_code" },
      ],
      filter: "stoke_number",
      dialogDelete: false,
      selectedItem: null,
      selectedId: null,
    };
  },
  mounted() {
    this.getOldInventory();
    const self = this;
    $("#example").on("click", ".delete-icon", function (e) {
      self.selectedId = e.currentTarget.dataset.secret;
      self.dialogDelete = true;
      e.preventDefault();
    });
  },
  methods: {
    ...mapActions({
      deleteParts: "partlist/deleteThirdPartyParts",
    }),
    reload() {
      try {
        this.$store.commit("setLoader", true);
        this.table.ajax
          .url(
            process.env.VUE_APP_API_HOST +
              OLD_INVENTORY +
              `?search[key]=${this.filter}`
          )
          .load();
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getOldInventory() {
      try {
        this.$store.commit("setLoader", true);
        this.table = $("#example").DataTable({
          ajax: {
            url:
              process.env.VUE_APP_API_HOST +
              OLD_INVENTORY +
              `?search[key]=${this.filter}`,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          columns: [
            {
              orderable: false,
              data: function (full) {
                return full.id || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_code || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_name || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.vin || "N/A";
              },
            },
            {
              data: function (row) {
                return row.year || "N/A";
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.model || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.grade || "N/A";
              },
            },
            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.amount)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: false,
            },
            {
              data: function (row) {
                return row.description || "N/A";
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.interchange_description || "N/A";
              },
            },
            {
              orderable: false,
              data: function (row) {
                return (
                  "$ " +
                  Number(row.sale_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
            },
            {
              data: function (row) {
                return row.miles || "N/A";
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.stoke_number || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.location || "N/A";
              },
            },
            {
              data: function (row) {
                return row.interchange || "N/A";
              },
              orderable: false,
            },
            {
              className: "action",
              orderable: false,
              data: function (row) {
                return `<ul class="action-list">
                <li>
                  <a
                    @click="
                      selectedItem = item.id;
                      deleteItemConfirm();
                    "
                    data-secret = ${row.id}
                    class="act-icon delete-icon tooltips"
                    data-tooltip="Delete"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30.002"
                      viewBox="0 0 30 30.002"
                    >
                      <g transform="translate(0 0)">
                        <path
                          d="M28.877,28.9a3.732,3.732,0,0,1-5.291,0L1.094,6.395A3.744,3.744,0,0,1,6.385,1.1L28.877,23.6a3.748,3.748,0,0,1,0,5.3ZM28.9,6.395,6.419,28.9a3.743,3.743,0,1,1-5.291-5.295L23.612,1.1A3.743,3.743,0,1,1,28.9,6.395Z"
                          transform="translate(0 0)"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>`;
              },
            },
          ],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async deleteCarParts() {
      try {
        this.$store.commit("setLoader", true);
        await this.deleteParts(this.selectedId);
        this.table.ajax.reload();
        this.dialogDelete = false;
        showSuccess("Parts deleted Successfully");
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
